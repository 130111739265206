.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-wrapper {
  width: 100%;
}

.bg-dark {
  background-image: linear-gradient(#242526, #242526);
}

body {
  background-color: rgb(77, 76, 76) !important;
}

.remove-scrollbar-windows::-webkit-scrollbar {
  display: none;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #18191a !important;
  }

  .nav-link {
    color: whitesmoke;
  }

  .nav-link:hover {
    color: whitesmoke;
    opacity: 0.7;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: rgb(239, 239, 239) !important;
  }

  .nav-link {
    color: inherit;
  }

  .nav-link:hover {
    color: inherit;
    opacity: 0.7;
  }

  .feed-activity {
    color: rebeccapurple;
  }

  .feed-weight {
    color: navy;
  }

  .feed-distance {
    color: maroon;
  }

  .feed-alternate {
    background-color: #f8f8f8;
  }
}

.round-box:hover {
  background-color: darkgrey;
  color: black;
}

.measurement-box {
  :hover {
    opacity: 0.5;
  }
  position: relative;
  transition: 250ms ease-in-out;
}

.leaderboard-iframe {
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
  width: 130%;
  height: 700px;
}
